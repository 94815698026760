.fileContainer {
    clear:both;
}

#header {
    height:80px;
    border-bottom: 1px solid black;
}
#file {
    float: left;
    height: 100%;
    margin: 20px;
}
#signout {
    float: right;
    border: 1px solid black;
    margin: 20px;
}