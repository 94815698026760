.fileItem {
    float: left;
    width: 150px;
    margin: 10px;
    padding: 5px;
    border: 1px solid black;
}

.title {
    margin: 20px 0;
    font-size: small;
}